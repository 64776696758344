<template>
  <div :class="$style.root">
    <div :class="$style.header">{{ field['label'] }}</div>
    <div class="mt-5">
      <VTextField v-model="query" label="Поиск по названию" outlined dense hideDetails="auto" clearable />
    </div>
    <VAlert v-if="message" type="error" class="mt-5 mb-0" outlined dense>{{message}}</VAlert>
    <div :class="$style.item" v-for="({options, title}) in items" :key="title">
      <div v-if="Object.keys(items).length > 1 && title" :class="$style.title">{{title}}</div>
      <VRow>
        <VCol v-for="{text, value} in options" cols="12" md="3" sm="6" :key="value">
          <div class="d-flex">
            <VTooltip right>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <VCheckbox v-model="subscribe['values']" :value="value" class="mt-0 pt-0" onIcon="mdi-email" offIcon="mdi-email" hideDetails />
                </div>
              </template>
              <span v-if="!~subscribe['values'].indexOf(value)">Подписаться на оповещения</span>
              <span v-else>Отписаться от оповещений</span>
            </VTooltip>
            <VCheckbox v-model="field['values']" :value="value" class="mt-0 pt-0" :label="text" hideDetails />
          </div>
        </VCol>
      </VRow>
    </div>
    <VAlert v-if="message" type="error" class="mb-0" outlined dense>{{message}}</VAlert>
  </div>
</template>

<script>
import { groupBy, filter, map, sortBy, trim, toLower, get, forEach } from 'lodash-es';

export default {
  name: 'Activity',
  props: {
    label: { type: String },
    field: { type: Object },
    subscribe: { type: Object },
    rules: { type: Array },
  },
  data() {
    return {
      query: '',
    }
  },
  computed: {
    items() {
      const reg = new RegExp(this.query, 'ig');
      const options = this.query ? filter(this.field.options, ({ text }) => reg.test(text)) : this.field.options;
      return sortBy(map(groupBy(options, 'name'), (options, title) => ({
        title,
        options,
      })), ({ title }) => get({
        'оборудование': 1,
        'строительство': 2,
        'пир': 3,
        'отипб': 4,
        'транспорт': 5,
        'горные работы': 6,
        'услуги общего профиля': 7,
        'работы услуги': 8,
      }, toLower(trim(title)), title));
    },
    message() {
      let message = '';
      forEach(this.rules, (func) => {
        const value = func(this.field['values']);
        if (typeof value === 'string') message = value;
      });
      return message;
    },
  }
}
</script>

<style module lang="scss">
.root {
  :global(.v-input__slot) {
    align-items: flex-start;
  }
}
.header {
  font-weight: 500;
  font-size: 18px;
  background: #fff7e4;
  border-bottom: 1px solid #e6cfbe;
  padding: 5px 16px;
  margin: 0 -16px;
}
.title {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
  margin-bottom: 15px;
}
.item {
  padding: 20px;
  margin: 0 -16px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
</style>
