<template>
  <div :class="$style.root">
    <VAlert v-if="!valid && !isSlaveUser" type="error">В Профиле не заполнены обязательные поля. Пожалуйста, заполните Профиль</VAlert>
    <VForm v-model="valid" lazy-validation  ref="form" @submit.prevent="onSave">
      <SectionCard label="Информация о пользователе">
        <div class="mb-5">
          <div :class="$style.header">Контактные данные</div>
          <VRow>
            <VCol v-for="key in ['LAST_NAME', 'NAME', 'SECOND_NAME']" cols="12" md="4" :key="key">
              <ProfileValue v-bind="fields[key]" :disabled="isEvrazEmployee" @update:values="(values) => fields[key]['values'] = values" />
            </VCol>
            <VCol cols="12" md="4">
                <ProfileValue v-bind="fields['EMAIL']" :disabled="isEvrazEmployee || isSlaveUser" @update:values="(values) => fields['EMAIL']['values'] = values" />
            </VCol>
            <VCol v-for="key in ['PERSONAL_PHONE']" cols="12" md="6" :key="key">
              <ProfileValue v-bind="fields[key]" :disabled="isEvrazEmployee" @update:values="(values) => fields[key]['values'] = values" />
            </VCol>
            <VCol v-if="isSlaveUser" cols="12">
                <div :class="$style.title">Ваши роли в организации</div>
                <div v-if="!currentRolesValue.length">
                    Список ваших ролей пуст. Обратитесь к контактному лицу от вашей организации:
                    {{ masterUser.name }} <a :href="`mailto:${masterUser.email}`">{{ masterUser.email }}</a>
                </div>
                <div v-for="({text}, index) in currentRolesValue" :key="index">
                    <VCheckbox
                            :class="[$style.roleCheckbox, 'mt-0', 'pt-0', 'mb-2']"
                            input-value="true"
                            hideDetails
                            disabled
                    >
                        <template v-slot:label><div v-html="text" /></template>
                    </VCheckbox>
                </div>
            </VCol>
            <VCol v-if="!isSlaveUser" cols="12">
              <VRow>
                <VCol v-for="key in ['UF_EXTRA_MAIL']" cols="12" md="6" :key="key">
                  <ProfileValue v-bind="fields[key]" :required="false" @update:values="(values) => fields[key]['values'] = values" />
                  <div class="mt-3">
                    <VBtn small depressed color="primary" @click="fields['UF_EXTRA_MAIL']['values'].push('')">Добавить e-mail</VBtn>
                  </div>
                </VCol>
              </VRow>
            </VCol>
          </VRow>
          <template v-if="!isEvrazEmployee && !isSlaveUser">
            <div :class="$style.item" cols="12">
              <div :class="$style.title">Смена текущего пароля</div>
              <Password />
            </div>
            <div :class="$style.item" cols="12">
              <div :class="$style.title">{{fields['UF_FIRST_GEN_CONTACT']['label']}}</div>
              <Person v-model="fields['UF_FIRST_GEN_CONTACT']['values'][0]" required />
            </div>
            <div v-for="(value, index) in fields['UF_CONTACT_PERSONS']['values']" :class="$style.item" cols="12" :key="index">
              <span :class="$style.remove" @click="fields['UF_CONTACT_PERSONS']['values'].splice(index, 1)">
                  Удалить
                  <VIcon small color="error">
                    mdi-close
                  </VIcon>
                </span>
              <div :class="$style.title">{{fields['UF_CONTACT_PERSONS']['label']}}</div>
              <Person v-model="fields['UF_CONTACT_PERSONS']['values'][index]"/>
            </div>
            <VBtn small depressed color="primary" @click="fields['UF_CONTACT_PERSONS']['values'].push({ position: '', email: '', phone: '' })">Добавить контактное лицо</VBtn>
          </template>
        </div>
        <div v-if="needShowSlavesBlock">
            <div :class="$style.header">{{fields['SLAVE_USERS']['label']}}</div>
            <div class="mb-5">
                <div v-for="(value, index) in fields['SLAVE_USERS']['values']" :class="$style.item" cols="12" :key="index">
                    <span :class="$style.remove" @click="onSlaveUserRemove(index)">
                        Удалить
                        <VIcon small color="error">
                            mdi-close
                        </VIcon>
                    </span>
                    <div :class="$style.title">
                        {{ slaveTitleControl(value) }}
                        <span v-if="!!value.id && !value.active" class="red--text">(регистрация не подтверждена сотрудником)</span>
                    </div>
                    <SlaveUser v-model="fields['SLAVE_USERS']['values'][index]" :readonly="!!value.id" class="mb-3" />
                    <div :class="$style.title">Роли сотрудника</div>
                    <VRow>
                        <VCol v-for="{text, value} in fields['SLAVE_USERS']['options']" cols="3" :key="text">
                            <VCheckbox
                                    class="mt-0 pt-0 mb-2"
                                    v-model="fields['SLAVE_USERS']['values'][index]['roles']"
                                    :rules="[(v) => !!v.length || 'Обязательно для заполнения']"
                                    :value="value"
                                    hideDetails
                            >
                                <template v-slot:label><div v-html="text" /></template>
                            </VCheckbox>
                        </VCol>
                        <VCol v-if="!get(fields, ['SLAVE_USERS', 'values', index, 'roles'], []).length && !valid" cols="12" class="pt-0 error--text">
                            Необходимо выбрать хотя бы одну роль
                        </VCol>
                    </VRow>
                </div>
                <VBtn
                        v-if="get(fields, ['SLAVE_USERS', values], []).length < settings.maxSlaveUsersCount"
                        @click="onSlaveUserAdd"
                        color="primary"
                        small
                        depressed
                >
                    Добавить сотрудника
                </VBtn>
            </div>
        </div>
        <template v-if="!isEvrazEmployee">
          <div>
            <div :class="$style.header">Регистрационные данные</div>
            <VRow>
              <VCol cols="12">
                <VRow>
                  <VCol v-for="key in ['UF_USER_TYPE']" cols="12" md="4" :key="key">
                    <ProfileValue v-bind="fields[key]" :disabled="isSlaveUser" :options="options[key] || fields[key]['options']" @update:values="(values) => fields[key]['values'] = values" />
                  </VCol>
                  <template v-if="isPerson">
                    <VCol v-for="key in ['UF_INN', 'UF_PASSPORT_NUMBER', 'UF_PASSPORT_DATE']" cols="12" md="4" :key="key">
                      <ProfileValue v-bind="fields[key]" :disabled="isSlaveUser" :options="options[key] || fields[key]['options']" @update:values="(values) => fields[key]['values'] = values" />
                    </VCol>
                  </template>
                  <template v-if="isCompany">
                    <VCol v-for="key in ['UF_FULLNAME', 'UF_SHORTNAME', 'UF_INN']" cols="12" md="4" :key="key">
                      <ProfileValue v-bind="fields[key]" :disabled="isSlaveUser" :options="options[key] || fields[key]['options']" @update:values="(values) => fields[key]['values'] = values" />
                    </VCol>
                    <VCol v-for="key in isEntrepreneur ? ['UF_OGRN'] : ['UF_KPP', 'UF_OGRN']" cols="12" md="4" :key="key">
                      <ProfileValue v-bind="fields[key]" :disabled="isSlaveUser" type="autocomplete" :options="options[key] || fields[key]['options']" @update:values="(values) => fields[key]['values'] = values" />
                    </VCol>
                  </template>
                  <template v-if="isForeign">
                    <VCol v-for="key in ['UF_FULLNAME', 'UF_SHORTNAME', 'UF_TAX_ID', 'UF_INN', 'UF_KPP']" cols="12" md="4" :key="key">
                      <ProfileValue
                          v-bind="fields[key]"
                          :disabled="isSlaveUser"
                          :rules="[]"
                          :options="options[key] || fields[key]['options']"
                          @update:values="(values) => fields[key]['values'] = values"
                      />
                    </VCol>
                  </template>
                </VRow>
              </VCol>
              <VCol cols="12">
                <div v-for="(key, index) in ['UF_JUR_ADRESS_JSON', 'UF_FACT_ADRESS_JSON', 'UF_POST_ADRESS_JSON']" :class="$style.item" :key="key">
                  <div :class="$style.title">{{fields[key]['label']}}</div>
                  <Address v-model="fields[key]['values'][0]" :disabled="isSlaveUser" :previous="fields[key]['hint']" :required="key !== 'UF_POST_ADRESS_JSON'" :match="[null, fields['UF_JUR_ADRESS_JSON'], fields['UF_FACT_ADRESS_JSON']][index]" />
                </div>
              </VCol>
            </VRow>
          </div>
          <div v-if="!isSlaveUser">
            <div :class="[$style.header, 'mb-0']">Банковские реквизиты</div>
            <div class="text-muted mt-4">Статус рассмотрения реквизитов можно будет уточнить в текущем блоке после сохранения данных профиля</div>
            <div v-for="(value, index) in fields['BANK_DETAILS']['values']" :class="$style.item" :key="index">
              <span :class="$style.remove" @click="get(fields, ['BANK_DETAILS', 'values'], []).splice(index, 1, ...get(fields, ['BANK_DETAILS', 'values'], []).length > 1 ? [] : [{ UF_BIK: '', UF_CORRESPONDENT_BIL: '', UF_BILL: '', UF_NAME: '' }])">
                  Удалить <VIcon small color="error">mdi-close</VIcon>
                </span>
              <div :class="$style.title">
                <template v-if="index">{{ fields['BANK_DETAILS']['label'] }}</template>
                <template v-else>
                  Банковские реквизиты
                  <VTooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <VIcon class="primary--text" v-bind="attrs" v-on="on">mdi-information-outline</VIcon>
                    </template>
                    Данные реквизиты могут быть использованы при заключении договора с поставщиком и не обязательны для заполнения на этапе участия в конкурентных процедурах
                  </VTooltip>
                </template>
              </div>
              <Bank v-model="fields['BANK_DETAILS']['values'][index]"/>
            </div>
            <VBtn v-if="get(fields, ['BANK_DETAILS', 'values'], []).length < 10" small depressed color="primary" @click="get(fields, ['BANK_DETAILS', 'values'], []).push({ UF_BIK: '', UF_CORRESPONDENT_BIL: '', UF_BILL: '', UF_NAME: '' })">Добавить банковские реквизиты</VBtn>
          </div>
        </template>
      </SectionCard>
      <template v-if="!isEvrazEmployee && !isSlaveUser">
        <SectionCard label="Данные о деятельности Организации">
          <div class="mb-5">
            <div :class="$style.header">Вид деятельности</div>
            <VRow>
              <VCol v-for="key in ['UF_PROJ_SERVICES', 'UF_WORK_WITH_TMC', 'UF_PROJ_ILLQ_TEND']" cols="12" md="auto" :key="key">
                <VCheckbox v-model="fields[key]['values'][0]" class="mt-0 pt-0 mb-2" :label="fields[key]['label']" hideDetails />
              </VCol>
            </VRow>
          </div>
          <div class="mb-5">
            <div :class="$style.header">{{fields['UF_COMPANU']['label']}}</div>
            <VRow>
              <VCol v-for="{text, value} in fields['UF_COMPANU']['options']" cols="3" :key="text">
                <VCheckbox
                    class="mt-0 pt-0 mb-2"
                    v-model="fields['UF_COMPANU']['values']"
                    :rules="[(v) => !!v.length || 'Обязательно для заполнения']"
                    :value="value"
                    hideDetails
                >
                  <template v-slot:label><div v-html="text" /></template>
                </VCheckbox>
              </VCol>
              <VCol v-if="!get(fields, ['UF_COMPANU', 'values'], []).length && !valid" cols="12" class="pt-0 error--text">
                Необходимо выбрать предприятие
              </VCol>
            </VRow>
          </div>
          <div v-if="fields['UF_PROJ_SERVICES']['values'][0]" class="mb-5">
            <Activity :field="fields['UF_ACTIVITY']" :subscribe="fields['UF_TYPE_WORK']"  :rules="[(v) => !!(v.length && v.length < 25) || 'Выберите от 1 до 24 видов выполняемых работ']" />
          </div>
          <div v-if="fields['UF_WORK_WITH_TMC']['values'][0]" class="mb-5">
            <Activity :field="fields['UF_ACTIVITY_TMC']" :subscribe="fields['UF_TYPE_WORK_TMC']"  :rules="[(v) => !!v.length || 'Выберите от 1 вида ТМЦ, оборудования']" />
          </div>
        </SectionCard>
        <SectionCard label="Данные для портала аукционов по реализации лома и отходов производства предприятиями ЕВРАЗ (auction.evraz.com) и для интернет-магазина ТМЦ (tmc.evraz.com)">
          <div class="mb-5">
            <VAlert type="warning" dense outlined>Допустимые форматы файлов: .doc, .docx, .pdf, .jpg, .png. Максимальный размер файла - 20 MB.</VAlert>
            <VRow>
              <VCol v-for="key in ['UF_ARTICLES_OF_ASSOC', 'UF_EGRUL', 'UF_ELECTION_PROTOCOL', 'UF_NALOG_CERT', 'UF_OGRN_LIC', 'UF_DIRECTOR_ORDER', 'UF_LICEN_METALL', 'UF_LICEN_WASTE_1_4', 'UF_LIC_WASTE_1_4_AKB', 'UF_SPEC_RECORD', 'UF_APPLICATION_FORM']" cols="12" md="6" :key="key">
                <VRow dense>
                  <VCol cols="12">
                    <div :class="$style.label" v-html="fields[key]['label']" />
                  </VCol>
                  <VCol cols="12">
                    <FileFieldMultiple
                        showCreateDate
                        :value="fields[key]['values'].map(value => ({ value }))"
                        :filled="!!get(fields, [key, 'values'], []).length"
                        @input="(values) => fields[key]['values'] = values.map(({ value }) => value)"
                    />
                  </VCol>
                </VRow>
              </VCol>
            </VRow>
          </div>
        </SectionCard>
        <SectionCard label="Соглашения">
          <VDialog max-width="600">
            <template v-slot:activator="{ on, attrs }">
              <VCheckbox v-model="fields['UF_EVRAZ_PD_AGREE']['values'][0]" :rules="[v => !!v || 'Обязательное поле']" class="mt-0 pt-0" hideDetails="auto">
                <template v-slot:label>
                  <div @click.prevent :class="$style.link" v-bind="attrs" v-on="on">{{fields['UF_EVRAZ_PD_AGREE']['label']}}</div>
                </template>
              </VCheckbox>
            </template>
            <AgreeCard />
          </VDialog>
        </SectionCard>
      </template>
      <VFooter app>
        <VProgressLinear v-if="pending" absolute indeterminate color="primary darken-2" :class="$style.progress" />
        <div class="py-3 grow">
          <VBtn color="primary" class="mr-2" depressed :disabled="pending" @click="onSave">Сохранить</VBtn>
          <VBtn v-if="diff" color="secondary" class="mr-2" depressed :disabled="pending" @click="onReset">Сбросить</VBtn>
        </div>
      </VFooter>
    </VForm>
  </div>
</template>

<script>
import {get, toString, map, omitBy, isEmpty, compact, filter, pick, values, keyBy} from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { inn } from '@/api/dadata';
import SectionCard from '@/components/user/SectionCard/SectionCard';
import FileFieldMultiple from '@/components/general/FileFieldMultiple/FileFieldMultiple';
import Address from './components/Address';
import Person from './components/Person';
import Bank from './components/Bank';
import Activity from './components/Activity';
import Password from './components/Password';
import ProfileValue from './components/ProfileValue';
import SlaveUser from './components/SlaveUser.vue';
import AgreeCard from '@/components/general/AgreeCard/AgreeCard';

export default {
  name: 'Profile',
  components: {
    AgreeCard,
    Person,
    Bank,
    Address,
    Activity,
    SectionCard,
    ProfileValue,
    SlaveUser,
    Password,
    FileFieldMultiple,
  },
  props: {
    values: { type: Object },
    masterUser: { type: Object },
    settings: { type: Object },
  },
  data() {
    return {
      valid: true,
      suggestions: {
        inn: null,
      },
      fields: JSON.parse(JSON.stringify(this.values)),
    };
  },
  computed: {
    ...mapGetters({
      pending: 'user/pending',
      user: 'user/current',
    }),
    isSlaveUser() {
      return !!get(this, 'masterUser.id', "").length;
    },
    // Сотрудник Evraz
    isEvrazEmployee() {
      return get(this.user, 'isEvrazEmployee', false) || (toString(get(this.fields, ['UF_USER_TYPE', 'values', 0])) === '7');
    },
    // Признак частного лица
    isPerson() {
      return !get(this.fields, ['UF_USER_TYPE', 'values', 0]);
    },
    // Признак иностранной компании
    isForeign() {
      return toString(get(this.fields, ['UF_USER_TYPE', 'values', 0])) === '18';
    },
    // Признак компании или ИП
    isCompany() {
      return toString(get(this.fields, ['UF_USER_TYPE', 'values', 0])) === '6';
    },
    // Признак ИП
    isEntrepreneur() {
      return this.isCompany && (toString(this.inn).length === 12);
    },
    options() {
      return omitBy({
        UF_KPP: compact(map(this.suggestions.inn, 'data.kpp')),
        UF_OGRN: compact(map(this.suggestions.inn, 'data.ogrn')),
      }, isEmpty)
    },
    kpp() {
      return toString(get(this.fields, ['UF_KPP', 'values', 0], ''));
    },
    inn() {
      return toString(get(this.fields, ['UF_INN', 'values', 0], ''));
    },
    tax() {
      return toString(get(this.fields, ['UF_TAX_ID', 'values', 0], ''));
    },
    diff() {
      return JSON.stringify(this.values) !== JSON.stringify(this.fields);
    },
    currentRolesValue() {
      return values(pick(keyBy(get(this.fields, ['ROLES', 'options'], []), 'value'), get(this.fields, ['ROLES', 'values'], [])));
    },
    needShowSlavesBlock() {
      return !this.isEvrazEmployee
        && !this.isSlaveUser
        && this.fields['CAN_ADD_SLAVES']['values']
        && (
          !!get(this.fields, ['SLAVE_USERS', 'values'], []).length
          || !!get(this.fields, ['SLAVE_USERS', 'options'], []).length
        );
    },
  },
  methods: {
    get,
    ...mapActions({
      updateProfileDetail: 'user/updateProfileDetail',
      fetchMenuItems: 'general/fetchMenuItems',
    }),
    onSave: function() {
      const values = this.fields;
      this.$refs.form.validate();
      this.updateProfileDetail({ values }).then(result => {
        if (result) {
          this.$notify({
            type: 'success',
            title: 'Успех',
            text: 'Профиль успешно изменен',
          });
        }
        return result;
      }).then(() => {
        this.fetchMenuItems();
      });
    },
    onReset: function() {
      this.fields = JSON.parse(JSON.stringify(this.values));
    },
    slaveTitleControl({ name, lastName, secondName }) {
      const fio = filter([lastName, name, secondName]).join(' ')
      return fio?.length
        ? `Сотрудник ${fio}`
        : 'Новый сотрудник';
    },
    onSlaveUserRemove(index) {
      this.$swal("Вы уверены что хотите удалить сотрудника? Действие нельзя будет отменить.").then(({ isConfirmed }) => {
        if (isConfirmed) {
          this.fields['SLAVE_USERS']['values'].splice(index, 1);
        }
      });
    },
    onSlaveUserAdd() {
      this.fields['SLAVE_USERS']['values'].push({
        email: '',
        name: '',
        lastName: '',
        secondName: '',
        phone: '',
        roles: get(this.fields, ['SLAVE_USERS', 'options'], []).length > 1 ? [] : [get(this.fields['SLAVE_USERS']['options'], '0.value')],
      })
    }
  },
  watch: {
    inn: {
      handler: function(query) {
        if (query && (query?.length === 10 || query?.length === 12)) {
          inn(query).then(({ suggestions }) => {
            this.suggestions.inn = suggestions;
            if (suggestions?.length === 1) {
              const item = get(suggestions, 0);
              this.fields['UF_KPP']['values'] = [get(item, 'data.kpp')];
              this.fields['UF_OGRN']['values'] = [get(item, 'data.ogrn')];
            }
          });
        }
      }
    },
    values: {
      handler: function() {
        this.onReset();
      }
    }
  },
  mounted() {
    this.$refs.form.validate();
  }
}
</script>

<style module lang="scss">
.root {
  :global(.v-expansion-panel) {
    border: 1px solid rgb(238, 238, 238);
  }
}
.progress {
  top: 0;
}
.header {
  font-weight: 500;
  font-size: 18px;
  background: #fff7e4;
  border-bottom: 1px solid #e6cfbe;
  padding: 5px 16px;
  margin: 0 -16px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
.label {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.title {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
  margin-bottom: 15px;
}
.subscribe {
  cursor: pointer;
  margin-right: 10px;
}
.item {
  padding: 20px;
  margin: 0 -16px;
  & + & {
    border-top: 1px dashed rgba(0, 0, 0, 0.1);
  }
}
.link {
  color: #f57f29;
  text-decoration: underline;
}

.remove {
  color: #ff5252;
  cursor: pointer;
  text-decoration: none;
  float: right;
}
.footer {
  padding: 20px 16px 0;
  margin: 0 -16px;
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}

.roleCheckbox {
  pointer-events: none;
}
</style>
