<template>
  <div :class="$style.root">
    <VRow dense>
      <VCol cols="12" md="6">
        <VTextField label="БИК" v-mask="'#########'" :rules="rules['UF_BIK']" :value="value.UF_BIK" @input="UF_BIK => $emit('input', {...value, UF_BIK })" outlined dense hideDetails="auto" />
      </VCol>
      <VCol cols="12" md="6">
        <VSelect v-if="options['UF_NAME']" label="Наименование банка, город" :items="options['UF_NAME']" :value="value.UF_NAME" @change="UF_NAME => $emit('input', {...value, UF_NAME })" outlined dense hideDetails="auto" />
        <VTextField v-else label="Наименование банка, город" :rules="rules['UF_NAME']" :value="value.UF_NAME" @input="UF_NAME => $emit('input', {...value, UF_NAME })" outlined dense hideDetails="auto" />
      </VCol>
      <VCol cols="12" md="6">
        <VSelect v-if="options['UF_CORRESPONDENT_BIL']" label="Корреспондентский счет" :items="options['UF_CORRESPONDENT_BIL']" :value="value.UF_CORRESPONDENT_BIL" @change="UF_CORRESPONDENT_BIL => $emit('input', {...value, UF_CORRESPONDENT_BIL })" outlined dense hideDetails="auto" />
        <VTextField v-else label="Корреспондентский счет" v-mask="'####################'" :rules="rules['UF_CORRESPONDENT_BIL']" :value="value.UF_CORRESPONDENT_BIL" @input="UF_CORRESPONDENT_BIL => $emit('input', {...value, UF_CORRESPONDENT_BIL })" outlined dense hideDetails="auto" />
      </VCol>
      <VCol cols="12" md="6">
        <VTextField label="Расчетный счет" v-mask="'####################'" :rules="rules['UF_BILL']" :value="value.UF_BILL" @input="UF_BILL => $emit('input', {...value, UF_BILL })" outlined dense hideDetails="auto" />
      </VCol>
    </VRow>
    <div v-if="status" class="mt-4">
      <VAlert :type="status.type" outlined dense :icon="false">{{ status.text }}</VAlert>
    </div>
  </div>
</template>

<script>
import { get, omitBy, compact, map, isEmpty, includes } from 'lodash-es';
import { bank } from '@/api/dadata';

export default {
  name: 'Bank',
  props: {
    required: { type: Boolean },
    value: {
      type: Object,
      default: () => ({
        UF_BIK: '',
        UF_BILL: '',
        UF_NAME: '',
        UF_CORRESPONDENT_BIL: '',
        UF_STATUS: '',
      }),
    }
  },
  data() {
    return {
      suggestions: {
        bik: null,
      },
    }
  },
  computed: {
    status() {
      if (includes(['DOCUMENT_USER_NEW_STATUS_CODE', 'DOCUMENT_USER_CHANGED_STATUS_CODE'], this.value['UF_STATUS'])) return {
        type: 'warning',
        text: 'Реквизиты приняты на проверку. Запрос может находиться в обработке до 2 календарных дней. Статус запроса Вы можете проверить в профиле по истечению указанного срока.',
      }
      if (includes(['DOCUMENT_USER_REJECTED_STATUS_CODE', 'DOCUMENT_USER_BLOCKED_MSG_STATUS_CODE'], this.value['UF_STATUS'])) return {
        type: 'error',
        text: 'Реквизиты не приняты. Необходимо проверить корректность введенных данных. В случае возникновения вопросов - свяжитесь с куратором.',
      }
      if (this.value['UF_STATUS'] === 'DOCUMENT_USER_PROCESSED_STATUS_CODE') return {
        type: 'success',
        text: 'Реквизиты успешно внесены в информационные системы.',
      }
      return null;
    },
    bik() {
      return get(this.value, 'UF_BIK', '');
    },
    options() {
      return omitBy({
        UF_NAME: compact(map(this.suggestions.bik, 'value')),
        UF_CORRESPONDENT_BIL: compact(map(this.suggestions.bik, 'data.correspondent_account')),
      }, isEmpty)
    },
    rules() {
      const required = (v) => (!!v || !this.required) || 'Обязательно для заполнения';
      const bill = (v) => new RegExp('^\\d{20}?$|^$').test(v) || 'Неверный формат Расчетного счета: введите 20 символов';
      const twenty = (v) => new RegExp('^\\d{20}?$|^$').test(v) || 'Неверный формат: введите 20 символов';
      const nine = (v) =>  new RegExp('^\\d{9}?$|^$').test(v) || 'Неверный формат БИК: введите 9 символов';

      return {
        UF_BIK: [required, nine],
        UF_BILL: [required, bill],
        UF_NAME: [required],
        UF_CORRESPONDENT_BIL: [required, twenty],
      }
    }
  },
  watch: {
    bik: {
      handler: function(query) {
        if (query && (query.length === 9)) {
          bank(query).then(({ suggestions }) => {
            this.suggestions.bik = suggestions;
            if (suggestions.length === 1) {
              const item = get(suggestions, 0);
              this.$emit('input', {
                ...this.value,
                UF_NAME: get(item, 'value'),
                UF_CORRESPONDENT_BIL: get(item, 'data.correspondent_account'),
              })
            }
          });
        }
      }
    },
  }
}
</script>

<style module lang="scss">
.root{}
</style>
